<template>
  <div class="bg-white rounded shadow-2xl mb-3">
    <div class="p-2">
      <div class="grid md:grid-cols-9 grid-cols-2 gap-2">
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>
        <div
          class="
            h-16
            bg-gray-200
            rounded-tr rounded-tl
            animate-pulse
            hidden
            md:block
          "
        ></div>

        <div class="h-16 bg-gray-200 rounded-tr rounded-tl animate-pulse"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderSkeleton",
};
</script>
