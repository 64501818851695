<template>
  <div class="container">
    <div class="p-title">My Loads</div>

    <div v-if="loading">
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
      <LoaderSkeleton></LoaderSkeleton>
    </div>
    <div class="table-responsive">
      <DataTable
        :value="myLoads"
        :paginator="true"
        :rows="10"
        sortMode="multiple"
        v-if="isSuccessfull"
        ref="dt"
        v-model:filters="filters1"
        filterDisplay="menu"
        v-show="!this.loading"
      >
        <template #header>
          <div class="flex justify-between items-center">
            <div class="no-loads mb-3">{{ responseText }}</div>
            <div>
              <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Keyword Search"
                />
              </span>
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Clear"
                class="p-button-outlined"
                @click="clearFilter1()"
              />
            </div>
          </div>
        </template>
        <Column field="LoadID" header="Load #" :sortable="true"></Column>
        <Column
          field="TotalAP"
          header="Payment Amount"
          :sortable="true"
        ></Column>
        <Column field="Origin" header="Origin" :sortable="true"></Column>
        <Column
          field="PickupDate"
          header="Pickup date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.PickupDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.PickupDate)
            }}
          </template>
        </Column>
        <Column
          field="Destination"
          header="Destination"
          :sortable="true"
        ></Column>
        <Column
          field="DeliveryDate"
          header="Delivery date"
          :sortable="true"
          dataType="date"
        >
          <template #body="{ data }">
            {{
              data.DeliveryDate == "0001-01-01T00:00:00"
                ? null
                : formatDate(data.DeliveryDate)
            }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { dataProvider } from "@/api/dataProvider";
import LoaderSkeleton from "@/components/common/LoaderSkeleton.vue";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
export default {
  components: {
    InputText,
    LoaderSkeleton,
  },
  data() {
    return {
      filters1: null,
      CarrierID: this.$store.state.login.CarrierID,
      CarrierPortalUserID: this.$store.state.login.CarrierPortalUserID,
      EmailAddress: this.$store.state.login.EmailAddress,
      myLoads: [],
      responseText: "",
      isSuccessfull: true,
      loading: false,
    };
  },
  created() {
    this.getMyLoads();
    this.initFilters1();
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    async getMyLoads() {
      this.loading = true;
      var MyLoads = await dataProvider.getMyLoads(
        this.CarrierID,
        this.CarrierPortalUserID,
        this.EmailAddress
      );

      this.responseText = MyLoads.ResponseText;
      this.myLoads = MyLoads.CarrierLoads;
      this.isSuccessfull = MyLoads.IsSuccessful;
      this.loading = false;
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>

<style lang="scss" scoped>
.p-subtitle {
  font-size: 20px;
  color: #181818;
  margin-bottom: 20px;
}
.main-btn {
  padding: 10px;
  .icon {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
}

.main-btn-lg {
  padding: 10px 30px;
  .icon {
    display: inline-block;
    fill: #fff;
    margin-right: 6px;
  }
}
.main-btn {
  padding: 10px;
  .icon {
    fill: #fff;
    width: 15px;
    height: 15px;
  }
}
.main-btn-lg {
  padding: 10px 30px;
}
</style>
